import { css } from '@emotion/react'
import { Component } from 'react'
// import PropTypes from 'prop-types'
import PropTypes from 'prop-types'
import {
    CardElevation,
    ButtonDegrade,
    Oferta
} from '@findep/mf-landings-core';
import {
    Typography,
    Container,
    Grid
} from '@material-ui/core';
import CarouselImg from '../img/CarouselImg.compilable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { navigate } from "../../helpers/queryNavigate"
// import {AttachMoney} from '@material-ui/icons';

import formatNumber from '../../helpers/formatNumber'
import { withThemeProps } from '@findep/microfronts-core';
import { restoreFromSessionStorage } from '../../components/pages/afi/restoreSessionStorage';

const imageContainer = css`
    display: flex;
    position: center;
    height: 250px;
`
const buttonCss = css`
    display: flex;
    justify-content: flex-center;
`
// preOferta.oferta.amount y preOferta.oferta.payment
class NumRastreo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tdatos: restoreFromSessionStorage('tdatos'),
            flujo: restoreFromSessionStorage('flujo'),
        }
        const { flujo } = this.state
        this.state = {
            ...this.state, props: [
                { icono: ArrowForwardIcon, titulo: this.props.ofertaMonto, texto: `${formatNumber(flujo?.preOferta?.oferta?.amount ?? 0)}` },
                { icono: ArrowForwardIcon, titulo: this.props.ofertaPago, texto: `${formatNumber(flujo?.preOferta?.oferta?.payment ?? '0 ')}` },
            ]
        }
    }

    async nextPage() {
        navigate(this.props.pageContext?.next?.path)
    }


    render() {
        const { pageContext } = this.props

        const {
            typographyTitle,
            typographySubtitle,
            typographyH2,
            buttonDegrade,
        } = this.props

        // console.log(pageContext)
        return (
            <Container justify="center" maxWidth="sm">
                <br />
                <Oferta datos={this.state.props} colorOne="#FF9F01" colorTwo="#FA4616" />
                <Typography align="center"  font-size="3rem" fontWeight='fontWeightBold'>
                    <h2><span style={{color:"#698d21"}}>{typographyTitle}</span></h2>
                </Typography>
                <CardElevation>
                    <Typography align="left">
                        {typographySubtitle}
                    </Typography>
                    <Typography align="center"  font-size="4rem" fontWeight='fontWeightBold'>
                        <h2><span style={{color:"#698d21"}}>{typographyH2}</span> {this.state.tdatos?.telefono}</h2>
                    </Typography>

                </CardElevation>
                <br />
                <Grid container justify="center">

                    <Grid item>
                        <div css={buttonCss} >
                            <ButtonDegrade
                                id="numero-rastreo-obtener-prestamo-button"
                                icon={ArrowForwardIcon}
                                textButton={buttonDegrade}
                                onClick={
                                    () => {
                                        this.nextPage()
                                    }
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
                <br />
                <br />
                <CardElevation>
                    <CarouselImg customCss={imageContainer} filename="3.svg" company={pageContext.company} />

                </CardElevation>
                <br />
            </Container>
        )
    }
}

export default withThemeProps(NumRastreo, 'VDPNNumRastreo')

NumRastreo.propTypes = {
    typographyTitle: PropTypes.string,
    typographySubtitle: PropTypes.string,
    typographyH2: PropTypes.string,
    buttonDegrade: PropTypes.string,
    ofertaMonto: PropTypes.string,
    ofertaPago: PropTypes.string,
}

NumRastreo.defaultProps = {
    typographyTitle: "Número de rastreo",
    typographySubtitle: "¿No tienes a la mano algún documento necesario? ¡No te preocupes! Puedes completar tu proceso de crédito en cualquier momento ingresando tu número de rastreo:",
    typographyH2: "No.",
    buttonDegrade: "OBTENER MI CRÉDITO",
    ofertaMonto: "Monto",
    ofertaPago: "Pago"
}
